/* src/pages/BlogPost.css */
.post-container {
    padding: 2rem;
    background: #f4f4f4;
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.post-container h1 {
    font-size: 2.5rem;
    color: #34495e;
    margin-bottom: 1rem;
    text-align: center;
}

.post-container p {
    font-size: 1.2rem;
    color: #7f8c8d;
    line-height: 1.8;
    text-align: justify;
}
