.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000;
  }
  
  .auth-card {
    background-color: #1a1a1a;
    border: 2px solid #00ccff;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(255, 0, 0, 0.897);
    color: #fff;
  }
  
  .auth-card h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #00ccff;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #00ccff;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #00ccff;
    border-radius: 4px;
    background-color: #000;
    color: #fff;
    box-sizing: border-box;
  }
  
  .form-group input:focus {
    border-color: #53ff03;
    outline: none;
  }
  
  .btn {
    width: 100%;
    padding: 10px;
    background-color: #000;
    border: 1px solid #00ccff;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .btn:hover {
    background-color: #53ff03;
    color: #000;
  }
  
  .or-container {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
  
  .line {
    flex: 1;
    border: none;
    height: 1px;
    background-color: #ccc;
    margin: 0 10px;
  }
  
  .or-text {
    color: #ccc;
  }
  
  .btn-group {
    display: flex;
    justify-content: space-between;
  }
  
  .btn-register, .btn-forgot {
    width: 48%;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .btn-register {
    background-color: #333;
    border: none;
  }
  
  .btn-register:hover {
    background-color: #00ccff;
    color: #000;
  }
  
  .btn-forgot {
    background-color: #333;
    border: none;
  }
  
  .btn-forgot:hover {
    background-color: #00ccff;
    color: #000;
  }
  
  .error {
    color: red;
    margin-top: 10px;
    text-align: center;
  }