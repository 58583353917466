@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

.container {
    background-color: #e0dfdf;
    box-shadow: 0 4px 12px rgb(255, 0, 0);
    border-radius: 12px;
    padding: 40px;
    width: 100%;
    max-width: 500px;
    text-align: center;
    margin: 20px auto;
}

.todo-title {
    margin-bottom: 20px;
    color: #5c5c5c;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
}

h1 {
    margin: 50px;
    color: #0011ac;
    font-weight: 800;
    font-size: 1.9rem;
}

.input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.input-container input[type="text"] {
    flex: 1;
    padding: 12px;
    border: 1px solid #e100ff;
    border-radius: 8px;
    margin-right: 10px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.input-container input[type="text"]:focus {
    border-color: #00e1ff;
    outline: none;
}

.input-container input[type="button"] {
    padding: 12px 20px;
    background-color: #32c21f;
    border: none;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.input-container input[type="button"]:hover {
    background-color: #882177;
    transform: translateY(-2px);
}

.todoList {
    list-style: none;
    padding: 0;
}

.todoList li {
    background-color: #f0f0f0;
    border: 2px solid #ff7300;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.todoList li:hover {
    box-shadow: 0 4px 12px rgba(22, 22, 22, 0.993);
    transform: translateY(-2px);
}

.todoList li p {
    flex: 1;
    margin: 0;
    color: #160a30;
    font-weight: 600;
    font-size: 28px;
}

.btn {
    cursor: pointer;
    padding: 6px;
    transition: color 0.3s ease, transform 0.2s ease;
}

.editBtn {
    color: #09ff00;
}

.editBtn:hover {
    color: #ffffff;
    transform: scale(1.1);
}

.deleteBtn {
    color: #dc3545;
}

.deleteBtn:hover {
    color: #ffffff;
    transform: scale(1.1);
}

/* Media query for smaller screens */
@media (max-width: 700px) {
    .container {
        padding: 20px;
        max-width: 90%;
    }

    h1 {
        font-size: 1.5rem;
    }

    .input-container {
        flex-direction: column;
    }

    .input-container input[type="text"] {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .todoList li {
        flex-direction: column;
        align-items: flex-start;
    }

    .todoList li p {
        font-size: 20px;
    }
}
