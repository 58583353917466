

/* index.css */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  color: #ffffff;
  background-color: #000000;
  transition: background-color 0.3s, color 0.3s;
}


.container {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

button {
  cursor: pointer;
}
