.home-text {
  text-align: center;
  color: rgb(214, 211, 211);
  font-size: 2.9em;
  margin-bottom: 40px;
}


/* Games I Play Section */
.section-title {
  color: #c9c9c9;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.games-section {
  padding: 20px;
  margin: 20px 0;
}

.intro-text {
  font-style: italic;
  font-size: 16px;
  text-align: left;
  color: #fcfcfc;
}

.games-title {
  font-size: 2.5em; /* Larger title for emphasis */
  margin-bottom: 20px;
  text-align: left; /* Align title to the left */
}


.games-list {
  list-style: none;
  padding: 0;
  font-size: 1.1em;
  text-align: left; /* Align text to the left */
  max-width: 800px; /* Constrain width for better readability */
}

.games-list li {
  margin: 8px 0;
}

/* Channel Section */

.channel-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 26px;
  justify-content: center;
  margin: 22px;
  padding: 20px;
}

.channel-card {
  position: relative;
  background-color: #000000;
  border: 1.3px solid #810606;
  padding: 24px;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 6px 10px rgba(262, 0, 0, 0.644);
}

.channel-card:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgb(255, 0, 0);
}

.channel-logo {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 14px;
  transition: transform 0.4s ease-in-out;
}

.channel-logo:hover {
  transform: scale(1.1);
  
}

.channel-description {
  color: #dbd0d0;
  font-size: 16px;
  margin-bottom: 4px;
}

.description-p {
  color: #ececec;
  text-align: left;
  padding: 20px;
  font-size: 18px;
  margin-bottom: -44px;
}

.explore-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 18px 28px;
  border-radius: 25px;
  font-weight: bold;
  transition: background-color 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.explore-button:hover {
  background-color: #00000000;
  color: rgb(255, 255, 255);
  animation: neonGlow 1s infinite alternate;
  transform: scale(1.1);
}



/* ID Section */
.id-section {
  padding: 20px;
  margin: 20px 0;
}

.id-title {
  color: #c9c9c9;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.id-section h2 {
  font-size: 2.5em; /* Larger title for emphasis */
  margin-bottom: 20px;
  text-align: left; /* Align title to the left */
}

.id-section ul {
  list-style: none;
  padding: 0px;
  font-size: 1.2em;
  text-align: left; /* Align text to the left */
  max-width: 800px; /* Constrain width for better readability */
}

.id-section li {
  margin: 5px 0;
}


.subscribers-section {
  text-align: center;
  margin: 20px;
}

.subscribers-title {
  color: #c9c9c9;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.subscribers-count {
  color: #00ff00;
  font-size: 2em;
  font-weight: bold;
  margin: 10px 0;
}

.hr-top,
.hr-bottom {
  border: 0;
  border-top: 2px solid #e0e0e0;
  margin: 20px 0;
}

.hr-bottom {
  margin-top: 40px;
}



/* New Card Linking to LinkPage Section */
.linkpage-section {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.linkpage-card {
  border: 1px solid #4c00ff;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(238, 0, 0, 0.6);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.linkpage-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
}

.linkpage-title {
  text-align: center;
  color: rgb(214, 211, 211);
  font-size: 1.8em;
  margin: 10px;
}

.linkpage-description {
  color: #ffffff;
  font-size: 1.1em;
  margin-bottom: 20px;
}

.linkpage-button {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 18px 28px;
  border-radius: 25px;
  font-weight: bold;
  display: inline-block;
  transition: background-color 0.4s ease-in-out, transform 0.4s ease-in-out;
  font-size: 1.1em;
}

.linkpage-button:hover {
  background-color: #00000000;
  color: rgb(255, 255, 255);
  animation: neonGlow 1s infinite alternate;
  transform: scale(1.1);
}


.subscribers-section {
  padding: 20px;
  margin: 20px 0;
  text-align: center;
}

.channel-name {
  text-align: center;
  color: rgb(211, 222, 255);
  font-size: 1.9em;
}


.subscribers-title {
  text-align: center;
  color: rgb(255, 0, 0);
  font-size: 2.2em;
}

.subscribers-count {
  text-align: center;
  color: rgb(209, 209, 209);
  font-size: 2.2em;
  display: inline-block;
  font-weight: 800;
  margin-bottom: 42px;
}

.hr-top {
    border: none;
    border-top: 4px solid #ff0000;
    margin-top: -20px;
    margin-bottom: 10px;
  }
  
  .hr-bottom {
    border: none;
    border-top: 4px solid #ff002b;
    margin-top: -20px;
    margin-bottom: 10px;
  }
  



/* Responsive Design */
@media (max-width: 700px) {
  .channel-section {
    grid-template-columns: 1fr; /* Stack channel cards vertically on small screens */
  }


  

}

