/* src/pages/Blog.css */
.blog {
    padding: 3rem 2rem;
    background: linear-gradient(135deg, #ece9e6, #ffffff);
    font-family: 'Arial', sans-serif;
  }
  
  .blog-title {
    text-align: center;
    font-size: 3rem;
    color: #2c3e50;
    margin-bottom: 1rem;
  }
  
  .blog-subtitle {
    text-align: center;
    font-size: 1.2rem;
    color: #7f8c8d;
    margin-bottom: 3rem;
  }
  
  .blog-posts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .blog-post {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .blog-post:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .blog-post-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-post-content {
    padding: 1.5rem;
  }
  
  .blog-post h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #34495e;
  }
  
  .blog-post h2 a {
    color: #2980b9;
    text-decoration: none;
  }
  
  .blog-post h2 a:hover {
    text-decoration: underline;
  }
  
  .blog-post p {
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 1rem;
  }
  
  .read-more {
    font-size: 1rem;
    color: #2980b9;
    text-decoration: none;
    font-weight: bold;
  }
  
  .read-more:hover {
    text-decoration: underline;
  }
  