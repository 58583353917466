/* WhackaMole.css */

.whack-a-mole-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #1f1c2c, #928dab);
    border-radius: 12px;
    padding: 20px;
    width: 350px;
    margin: 50px auto;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  
  .game-title {
    text-align: center;
    font-size: 26px;
    color: #fff;
    margin-bottom: 20px;
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.7);
  }
  
  .top-scores {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 10px;
    left: 0;
    padding: 0 20px;
    color: #fff;
  }
  
  .last-score, .highest-score {
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 8px;
  }
  
  .score-board, .missed-board {
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 8px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    grid-gap: 15px;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .mole {
    width: 100px;
    height: 100px;
    background-color: #282c34;
    border-radius: 50%;
    border: 4px solid #00ccff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .mole.active {
    background-color: #ff5252;
    transform: scale(1.2);
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.8);
  }
  
  .mole-img {
    width: 70%;
    height: 70%;
    background-image: url('https://image.shutterstock.com/image-vector/cute-mole-cartoon-character-260nw-1660713599.jpg');
    background-size: cover;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
  }
  
  .start-button, .pause-button, .reset-button, .exit-button {
    background-color: #00ccff;
    border: none;
    color: #fff;
    padding: 12px 30px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s;
    margin: 10px;
  }
  
  .start-button:hover, .pause-button:hover, .reset-button:hover, .exit-button:hover {
    background-color: #53ff03;
    transform: scale(1.05);
    color: #282c34;
  }
  
  .game-over-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  }
  
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  