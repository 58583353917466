/* src/pages/ResetPassword.css */
.reset-password {
    padding: 2rem 1rem;
    max-width: 400px;
    margin: 0 auto;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  label {
    font-weight: bold;
  }
  
  input {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  button {
    background-color: #f0a500;
    color: #fff;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #e09400;
  }
  
  p {
    text-align: center;
  }
  