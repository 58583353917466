/* QR Code Generator Container */
.qr-generator-container {
    color: #000;
    padding: 40px;
    background: #f5f5f5;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-width: 1200px;
    margin: 0 auto;
}

h1 {
    margin: 50px;
    color: #0011ac;
    font-weight: 800;
    font-size: 1.9rem;
}

.qr-title {
    margin-bottom: 20px;
    color: #5c5c5c;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
}


/* QR Content Area */
.qr-content {
    background-color: #fff;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Upload Label */
.qr-upload-label {
    margin-top: 20px;
    cursor: pointer;
}

/* QR Code Container */
.qr-code-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Container for additional elements */
.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: -0.25em;
    margin-bottom: -0.25em;
}

/* Icons within the container */
.icon {
    width: 1.5em;
    height: 1.5em;
    margin-left: 0.5em;
    fill: white;
    transition: opacity 0.3s ease-in-out;
}

.icon.active {
    display: none;
    fill: #2196f3;
}

.check[type="checkbox"]:checked + .container .icon.active {
    display: inline-block;
    animation: pop 0.5s ease-in-out;
}

.check[type="checkbox"]:checked + .container .icon.inactive {
    display: none;
}

/* Animation keyframes for the icon pop effect */
@keyframes pop {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

/* Delete Button */
.deleteButton {
    width: 130px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    background-color: transparent;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    overflow: hidden;
}

.deleteButton svg {
    width: 44%;
}

.deleteButton:hover {
    background-color: rgb(237, 56, 56);
    overflow: visible;
}

.bin path {
    transition: all 0.2s;
}

.deleteButton:hover .bin path {
    fill: #fff;
}

.deleteButton:active {
    transform: scale(0.98);
}


/* Responsive Design */
@media (max-width: 900px) {
    .qr-generator-container {
        padding: 20px;
    }
    
    .qr-code-container {
        margin-top: 15px;
    }
}
