
/* Container to wrap content and footer */
.main-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
  }
  
  /* Auth Card Styles */
  .auth-card {
    background-color: #1a1a1a;
    border: 2px solid #00ccff;
    border-radius: 8px;
    padding: 30px 25px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 8px 16px rgba(0, 204, 255, 0.2);
  }
  
  .auth-card h2 {
    text-align: center;
    margin-bottom: 25px;
    color: #00ccff;
    font-size: 28px;
  }
  
  .form-group {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    color: #00ccff;
    font-weight: bold;
  }
  
  .password-container {
    position: relative;
  }
  
  .password-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #00ccff;
    cursor: pointer;
    font-size: 18px;
  }
  
  .form-group input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #00ccff;
    border-radius: 4px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    transition: border-color 0.3s;
  }
  
  .form-group input:focus {
    border-color: #53ff03;
    outline: none;
  }
  
  .btn {
    width: 100%;
    padding: 12px;
    background-color: #000;
    border: 2px solid #00ccff;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-top: 10px;
  }
  
  .btn:hover {
    background-color: #53ff03;
    color: #000;
  }
  
  .or-container {
    display: flex;
    align-items: center;
    margin: 25px 0;
  }
  
  .line {
    flex: 1;
    height: 1px;
    background-color: #ccc;
    margin: 0 10px;
  }
  
  .or-text {
    color: #ccc;
    font-size: 14px;
  }
  
  .btn-group {
    display: flex;
    justify-content: center;
  }
  
  .btn-login {
    padding: 10px 20px;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    color: #fff;
    background-color: #00ccff;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
    border: 2px solid #00ccff;
  }
  
  .btn-login:hover {
    background-color: #53ff03;
    color: #000;
    border-color: #53ff03;
  }
  
  .error-message,
  .success-message {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 10px;
    border-radius: 4px;
  }
  
  .error-message {
    background-color: rgba(255, 59, 59, 0.1);
    color: #ff3b3b;
    border: 1px solid #ff3b3b;
  }
  
  .success-message {
    background-color: rgba(83, 255, 3, 0.1);
    color: #53ff03;
    border: 1px solid #53ff03;
  }
  
  
  /* Media Queries for Responsiveness */
  
  /* Tablets */
  @media (max-width: 768px) {
    .auth-card {
      padding: 25px 20px;
    }
  
    .auth-card h2 {
      font-size: 24px;
    }
  
    .form-group input {
      font-size: 15px;
      padding: 10px 12px;
    }
  
    .btn {
      font-size: 16px;
      padding: 10px;
    }
  
    .btn-login {
      font-size: 15px;
      padding: 8px 16px;
    }
  }
  
  /* Mobile Devices */
  @media (max-width: 480px) {
    .auth-card {
      padding: 20px 15px;
    }
  
    .auth-card h2 {
      font-size: 22px;
    }
  
    .form-group label {
      font-size: 14px;
    }
  
    .form-group input {
      font-size: 14px;
      padding: 10px;
    }
  
    .password-icon {
      font-size: 16px;
      right: 10px;
    }
  
    .btn {
      font-size: 15px;
      padding: 10px;
    }
  
    .btn-login {
      font-size: 14px;
      padding: 8px 14px;
    }
  
    .or-text {
      font-size: 13px;
    }
  }
  