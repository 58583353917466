
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #000000;
  }
  
  .container {
    max-width: 580px;
    margin-top: 25px;
    padding: 70px;
    background-color: #0e0e0e;
    border-radius: 18px;
    box-shadow: 0 0 32px rgb(255, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .winner-name {
    margin-bottom: 20px;
    color: #5c5c5c;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
  }
  
  .turn-indicator {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .turn {
    font-size: 20px;
    color: #9b9b9b;
  }
  
  .turn.active {
    font-weight: bold;
    color: #007bff;
  }
  
  .game-board {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-bottom: 50px;
  }
  
  .square {
    width: 100px;
    height: 100px;
    border: 3px solid #007bff;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    color: #007bff;
    cursor: pointer;
    transition: all 0.4s ease;
    box-shadow: 0 4px 8px rgba(74, 156, 7, 0.966);
  }
  
  .square:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgb(0, 123, 255);
  }
  
  .square:active {
    transform: scale(0.95);
  }
  
  .square.occupied {
    pointer-events: none;
  }
  
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 40px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 20px rgba(167, 71, 71, 0.5), 0 0 40px rgrgba(121, 20, 20, 0.5)nset;
    border-radius: 10px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: #007bff;
    animation: fadeInDown 0.5s ease, glow 2s infinite alternate;
    cursor: pointer;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 20px rgba(0, 123, 255, 0.7), 0 0 40px rgba(0, 123, 255, 0.7) inset;
    }
    to {
      box-shadow: 0 0 30px rgba(0, 123, 255, 0.7), 0 0 50px rgba(0, 123, 255, 0.7) inset;
    }
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .btn {
    padding: 10px 20px;
    background-color: #1613c0;
    color: #ddd;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .btn:hover {
    background-color: #7b1bc9;
    color: #00ff2a;
  }
  
  .exit-btn {
    background-color: #392cf1;
    margin-top: 10px;
  }
  
  .exit-btn:hover {
    background-color: #ff0000;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  p a {
    color: #a0a0a0;
    text-decoration: underline;
    transition: color 0.3s ease;
  }
  
  p a:hover {
    color: #007bff;
  }
  