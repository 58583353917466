footer {
  background-color: #030303;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 1px 8px rgba(134, 12, 233, 0.842); /* Subtle shadow */
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 15px;
  flex-wrap: wrap; /* Wrap icons on smaller screens */
}

.icon {
  color: #ffffff; /* Default color for icons */
  transition: color 0.3s, transform 0.3s; /* Smooth transition for color and scale */
}

.icon:hover {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

/* Specific colors for each icon */
.instagram:hover {
  color: #E4405F; /* Instagram color */
}

.discord:hover {
  color: #7289DA; /* Discord color */
}

.youtube:hover {
  color: #FF0000; /* YouTube color */
}

.whatsapp:hover {
  color: #25D366; /* WhatsApp color */
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center; /* Center text on all screen sizes */
}

.footer-bottom p {
  font-size: 1em;
  margin: 0;
}

.privacy-policy {
  color: #ffffff; /* Color for the privacy policy link */
  font-size: 0.9em;
  text-decoration: underline;
  cursor: pointer;
}

.privacy-policy:hover {
  color: #ff0000; /* Change color on hover */
}

/* Responsive design for screens 900px or smaller */
@media (max-width: 900px) {
  footer {
    padding: 15px;
  }

  .social-icons {
    gap: 20px; /* Reduce gap between icons on smaller screens */
  }

  .footer-bottom p {
    font-size: 0.9em; /* Slightly smaller font size */
  }

  .privacy-policy {
    font-size: 0.85em; /* Slightly smaller font size */
  }
}
