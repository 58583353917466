/* Contact Page Styling */
.contact {
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
}

.contact h1 {
  text-align: center;
  color: rgb(214, 211, 211);
  font-size: 2.9em;
  margin-bottom: 40px;
  margin-top: 70px;
}

.contact-form {
  margin-top: 50;
  background-color: #000000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgb(255, 0, 0);
  margin-bottom: 60px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #222;
  color: #fff;
  box-sizing: border-box;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #aaa;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #66ff00;
  outline: none;
}

.submit-button {
  background-color: #f39c12;
  color: #fff;
  border: none;
  padding: 15px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: block;
  margin: 0 auto;
}

.submit-button:hover {
  background-color: #3f22e6;
  transform: scale(1.05);
}

.status-message {
  color: #d6d6d6;
  margin-top: 20px;
  font-size: 1.2rem;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 900px) {
  .contact {
    padding: 15px;
  }

  .contact h1 {
    font-size: 2rem;
  }

  .contact-form {
    padding: 15px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.9rem;
    padding: 8px;
  }

  .submit-button {
    font-size: 0.9rem;
    padding: 12px 18px;
  }

  .status-message {
    font-size: 1rem;
  }
}

/* For very small screens (e.g., mobile phones) */
@media (max-width: 480px) {
  .contact {
    padding: 10px;
  }

  .contact h1 {
    font-size: 1.8rem;
  }

  .contact-form {
    padding: 10px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.8rem;
    padding: 6px;
  }

  .submit-button {
    font-size: 0.8rem;
    padding: 10px 16px;
  }

  .status-message {
    font-size: 0.9rem;
  }
}

