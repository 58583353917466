/* Hamburger Menu Styling */
.hamburger {
  display: none; /* Hide hamburger menu by default */
  cursor: pointer;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  height: 3em;
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: white;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
  transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}

/* Header Styling */
header {
  background-color: #000000;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 4px rgba(214, 27, 27, 0.5);
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, backdrop-filter 0.3s ease;
}

header.scrolled {
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
}

/* Logo Styling */
.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  width: auto;
}

/* Navigation Menu Styling */
nav {
  display: flex;
  align-items: center;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 25px;
}

nav ul li {
  margin: 0;
}

nav ul li a {
  color: #ffffff;
  text-decoration: underline 2px;
  text-underline-offset: 6px;
  text-decoration-color: #3407fff5;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  text-decoration: underline rgb(255, 255, 255) 4px;
  text-underline-offset: 6px;
  color: #128ef3;
}

/* Responsive Design */
@media (max-width: 900px) {
  .hamburger {
    display: block; /* Show hamburger menu on small screens */
  }

  nav {
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #000;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  nav.open {
    height: calc(100vh - 60px);
  }

  nav ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  nav ul li {
    text-align: center;
  }
}

@media (min-width: 901px) {
  .hamburger {
    display: none; /* Hide hamburger menu on large screens */
  }

  nav {
    position: relative;
    top: 0;
    right: 0;
    background-color: transparent;
    height: auto;
    overflow: visible;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
  }

  nav ul {
    flex-direction: row;
    width: auto;
  }

  nav ul li {
    text-align: left;
  }
}
