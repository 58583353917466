.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.not-found h1 {
  color: #dc3545;
}
