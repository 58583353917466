/* Gallery styles */
.gallery h1 {
    color: rgb(214, 211, 211);
    font-size: 2.9em;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 14px;
    padding: 40px;
    transition: filter 0.3s ease;
  }
  
  .gallery-item {
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(243, 7, 7, 0.616);
  }
  
  .gallery-item:hover {
    border-radius: 10px;
    transform: scale(1.08);
    box-shadow: 0 6px 15px rgb(255, 0, 0);
  }
  
  .full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(7, 7, 7, 0.815);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1000;
    overflow: hidden;
  }
  
  .full-screen img {
    max-width: 90%;
    max-height: 80%;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgb(0, 0, 0);
  }
  
  .full-screen .close-btn,
  .full-screen .prev-btn,
  .full-screen .next-btn {
    position: absolute;
    background: rgba(129, 129, 129, 0);
    border: none;
    color: #ffffff;
    padding: 12px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 24px;
  }
  
  .full-screen .close-btn {
    top: 20px;
    right: 20px;
  }
  
  .full-screen .prev-btn {
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .full-screen .next-btn {
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .full-screen .prev-btn:hover,
  .full-screen .next-btn:hover {
    color: #00ff2a;
  }
  
  .full-screen .full-screen-actions {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  /* Download Button styles */
  .download-btn {
    --white: #ffffff;
    cursor: pointer;
    background: linear-gradient(to bottom, #6e3bff, #7e51ff);
    color: #ffffff;
    border: 1px solid #af93ff;
    border-radius: 8px;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 8px 10px -4px #503b89, 0 0 0 2px #562cce;
    font-size: 25px;
  }
  
  .download-btn svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
  }
  
  .share-btn {
    --white: #ffffff;
    cursor: pointer;
    background: linear-gradient(to bottom, #6e3bff, #7e51ff);
    color: #ffffff;
    border: 1px solid #af93ff;
    border-radius: 8px;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 8px 10px -4px #503b89, 0 0 0 2px #562cce;
    font-size: 25px;
  }
  
  /* Blurred background when full screen is active */
  .gallery-container.blurred {
    filter: blur(5px);
  }
  
  /* Responsive styles for screens 900px and smaller */
  @media (max-width: 900px) {
    .gallery-container {
      padding: 20px;
    }
  
  
  
    .full-screen .close-btn,
    .full-screen .prev-btn,
    .full-screen .next-btn {
      padding: 2px;
      font-size: 28px;
    }
  
    .full-screen .close-btn {
      top: 10px;
      right: 40%;
      color: red;
    }
  
    .full-screen .prev-btn {
      left: -40%;
      color: #00ff2a;
    }
  
    .full-screen .next-btn {
      right: -40%;
      color: #00ff2a;
    }
  }
  