.back-to-top {
    position: fixed;
    bottom: 94px;
    right: 14px;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  
  .scroll-to-top {
    background: linear-gradient(135deg, #6e3f7b, #d97c8c); /* Gradient background */
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 50px; /* Rounded rectangle */
    cursor: pointer;
    box-shadow: 0 6px 20px rgb(228, 228, 228);
    transition: background 0.3s, transform 0.3s ease;
    font-size: 16px; /* Text size */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Arial', sans-serif;
  }
  
  .scroll-to-top:hover {
    background: linear-gradient(135deg, #424b83, #6e3f7b); /* Reverse gradient on hover */
    transform: scale(1.1);
  }
  
  .scroll-to-top:active {
    transform: scale(0.95);
  }
  
  .back-to-top.show {
    opacity: 1;
    visibility: visible;
  }
  
  /* Arrow-only button for screens 900px or smaller */
  @media (max-width: 900px) {
    .scroll-to-top {
      padding: 10px;
      font-size: 20px;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background: radial-gradient(circle, #6e3f7b, #d97c8c); /* Radial gradient */
      box-shadow: 0 4px 15px rgb(104, 104, 104);
      font-size: 24px;
    }
  
    .scroll-to-top::before {
      content: '↑'; /* Arrow icon */
      font-size: 24px;
    }
  
    /* Hide text on smaller screens */
    .scroll-to-top span {
      display: none;
    }
  }
  