.services {
    padding: 20px;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .services h1 {
    color: rgb(214, 211, 211);
    font-size: 2.9em;
    margin-bottom: 40px;
  }
  
  .container {
    width: 90%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
  
  .experience-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .experience-item {
    background-color: #0e0c0c; /* Dark background for items */
    border-radius: 8px;
    padding: 20px;
    width: calc(25% - 20px); /* Four items per row with gap */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .experience-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  }
  
  .experience-icon {
    color: #12f312;
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .experience-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .experience-item p {
    font-size: 1rem;
    color: #ddd; /* Light gray text */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .experience-item {
      width: calc(50% - 20px); /* Two items per row on tablets and smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .experience-item {
      width: 100%; /* One item per row on small screens */
    }
  }
  
  