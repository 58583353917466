/* General Styles */

body {
  font-family: 'Arial', sans-serif;
  background-color: #020202;
  color: white;
}


/* Custom Scrollbar for Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the track */
}

::-webkit-scrollbar-thumb {
    background: #888888; /* Color of the scrollbar handle */
    border-radius: 10px; /* Rounded corners for the scrollbar handle */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar handle on hover */
}

/* Custom Scrollbar for Firefox */
html {
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: #fbff00 #0044ff; /* Color of the scrollbar handle and track */
}

/* Apply custom scrollbar to a specific class */
.custom-scrollbar {
    overflow-y: scroll;
    height: 200px; /* Example height */
}

/* Apply the same custom scrollbar styles for the class */
.custom-scrollbar::-webkit-scrollbar {
    width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

