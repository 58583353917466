/* Projects.css */

.projects {
  padding: 20px;
  background-color: #000000;
  color: #c9c7c7;
  text-align: center;
}

.projects h1 {
  color: rgb(214, 211, 211);
  font-size: 2.9em;
  margin-bottom: 40px;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.project-card {
  background: #050505;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 8px rgb(148, 148, 148);
  transition: transform 0.3s, box-shadow 0.3s;
}

.project-card h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.project-card p {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.project-link {
  color: #00aaff;
  text-decoration: none;
  font-weight: bold;
  border: 2px solid #00aaff;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.project-link:hover {
  background-color: #002fff;
  color: #ffffff;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgb(255, 0, 0);
}

@media (max-width: 700px) {
  .projects-container {
    flex-direction: column;
    align-items: center;
  }

  .project-card {
    width: 90%;
  }

  .project-card h2 {
    font-size: 1.6em;
  }

  .project-card p {
    font-size: 0.9em;
  }
}
