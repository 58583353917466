.password-generator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    padding: 20px;
  }
  
  .password-generator {
    background: linear-gradient(145deg, #1a1a1a, #0d0d0d);
    padding: 40px;
    border-radius: 12px;
    max-width: 450px;
    width: 100%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  
  .header p {
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  .title {
    margin-bottom: 20px;
    color: #ffffff;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
  }
  
  h1 {
    margin: 50px;
    color: #0011ac;
    font-weight: 800;
    font-size: 1.9rem;
  }
  
  .input-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-box {
    width: 100%;
    margin-bottom: 25px;
    position: relative;
  }
  
  .input-box input {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    background-color: #262626;
    color: #fff;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .input-box .copy-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    font-size: 18px;
    cursor: pointer;
    color: #00ccff;
  }
  
  .range-section {
    width: 100%;
    margin-bottom: 25px;
  }
  
  .range-label {
    margin-bottom: 8px;
    font-size: 14px;
    color: #00ccff;
  }
  
  .range-input {
    width: 100%;
    margin-bottom: 10px;
    height: 5px;
    background: #00ccff;
    outline: none;
    border-radius: 5px;
  }
  
  .range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
  
  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  
  .option {
    flex: 0 0 48%;
    display: flex;
    align-items: center;
    color: #ccc;
    font-size: 14px;
  }
  
  .option input[type="checkbox"] {
    margin-right: 10px;
    accent-color: #00ccff;
  }
  
  .generate-btn {
    background-color: #00ccff;
    color: #000;
    padding: 14px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .generate-btn:hover {
    background-color: #008cff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 900px) {
    .password-generator {
      padding: 20px;
      max-width: 100%;
    }
  
    .title {
      margin-bottom: 20px;
      color: #ffffff;
      font-size: 17px;
      text-transform: uppercase;
      text-align: center;
    }
  
  
    .h1 {
      font-size: 1.5rem;
      margin: 30px;
    }
  
    .input-box input {
      padding: 10px;
      font-size: 14px;
    }
  
    .generate-btn {
      padding: 12px;
      font-size: 14px;
    }
  }
  