.color-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    padding: 20px;
  }

  h1 {
    color: rgb(214, 211, 211);
    font-size: 2.9em;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .color-picker-header {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
    transition: color 0.3s ease;
  }
  
  .color-display-container {
    margin-bottom: 20px;
  }
  
  .color-display {
    width: 200px;
    height: 200px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 12px rgb(255, 3, 3);
    transition: all 0.3s ease;
    background: linear-gradient(to right, #f0f0f0, #ffffff);
  }
  
  .color-display:hover {
    border: 2px solid #ae00ff;
    box-shadow: 0 4px 16px rgb(255, 217, 0);
  }
  
  .color-display-text {
    color: #333;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  
  .color-code {
    font-size: 14px;
    color: #666;
  }
  
  .picker-popup {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgb(0, 0, 0);
    padding: 15px;
    width: 300px;
    box-sizing: border-box;
    z-index: 1000;
  }
  
  .close-btn {
    background-color: #ff0000;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .close-btn:hover {
    background-color: rgb(94, 255, 0);
  }
  
  .copy-btn {
    background-color: #00ccff;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .copy-btn:hover {
    background-color: #0099cc;
  }
  
  .color-search {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 6px;
    border: 2px solid #00ccff;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(41, 41, 41, 0.966);
  }
  
  .color-list {
    width: 100%;
    max-width: 800px;
  }
  
  .color-list h3 {
    margin-bottom: 10px;
    font-size: 22px;
    color: #d6d6d6;
  }
  
  .info-text {
    margin-bottom: 15px;
    font-size: 16px;
    color: #a8a8a8;
  }
  
  .color-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }
  
  .color-card {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .color-card:hover {
    transform: scale(1.05);
  }
  
  .color-info {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  
  .color-name {
    display: block;
    font-size: 16px;
  }
  
  .color-code {
    font-size: 14px;
  }
  
  .popup-message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #00ccff;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    transition: opacity 0.5s ease;
  }
  