/* About Page Styling */
.about h1 {
  color: rgb(214, 211, 211);
  font-size: 2.9em;
  text-align: center;
  margin-bottom: 40px;
}


.experience-section {
  border-radius: 8px;
  text-align: center;
  }

.experience-section h2 {
  padding-top: 4px;
  margin-inline: 14px;
  padding-inline: 8px;
  text-decoration: underline rgb(150, 150, 150) 2px;
  text-underline-offset: 9px;
  font-size: 2.2rem;
  color: #32353b; /* Accent color */
  margin-bottom: 10px;
}


.experience-section p {
  padding-top: 8px;
  margin-inline: 14px;
  padding-inline: 8px;
  text-decoration: underline rgba(0, 255, 42, 0.568) 1px;
  text-underline-offset: 5px;
  font-size: 1.4rem;
  font-style: italic;
  color: #412841; /* Accent color */
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .experience-item {
    width: calc(50% - 20px); /* Two items per row on tablets and smaller screens */
  }
}

@media (max-width: 480px) {
  .experience-item {
    width: 100%; /* One item per row on small screens */
  }
}


.heart-btn {
  margin-top: 24px;
  margin-bottom: 44px;
  font-size: 2rem;
  padding: 0.7rem 2.4rem;
  letter-spacing: .3rem;
  border-radius: 1.5rem;
  position: relative;
  background-color: #e8e8e8;
  color: red;
  border: none;
  text-shadow: 0 0 3px #ff6e6e;
  overflow: hidden;
  cursor: pointer;
  transition: 0.7s;
  z-index: 2;
}

.heart1 svg {
  position: absolute;
  left: 4.5rem;
  top: 1.8rem;
  opacity: 0;
  fill: red;
  stroke: none;
  transition: 0.7s;
  width: 2rem;
  height: 2rem;
  animation: move1 5s ease-in-out infinite alternate-reverse;
}

.heart2 svg {
  position: absolute;
  left: 0.8rem;
  opacity: 0;
  top: 0.3rem;
  width: 1.5rem;
  height: 1.5rem;
  fill: red;
  stroke: none;
  transform: rotate(-20deg );
  animation: move2 5s ease infinite alternate-reverse;
}

.heart3 svg {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  opacity: 0;
  width: 1.4rem;
  height: 1.4rem;
  transform: rotate(20deg);
  fill: red;
  stroke: none;
  z-index: -1;
  animation: move3 5s ease infinite alternate-reverse;
}

@keyframes move1 {
  0% {
    top: 2rem;
  }

  50% {
    top: 4rem;
  }

  100% {
    top: 2rem;
  }
}

@keyframes move2 {
  0% {
    top: 0.3rem;
  }

  50% {
    top: 1.5rem;
  }

  100% {
    top: 0.3rem;
  }
}

@keyframes move3 {
  0% {
    top: 1.5rem;
  }

  50% {
    top: .6rem;
  }

  100% {
    top: 1.5rem;
  }
}

.heart-btn:hover .heart1 svg,
.heart-btn:hover .heart2 svg,
.heart-btn:hover .heart3 svg {
  opacity: 1;
  filter: drop-shadow(0 0 10px #ff6e6e)
}

.heart-btn:hover {
  color: red;
  box-shadow: 0 0 10px inset #ff6e6e;
  scale: 105%;
}

.heart-btn:active {
  scale: 100%;
}