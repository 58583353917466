h1 {
  text-align: center;
  color: rgb(214, 211, 211);
  font-size: 2.9em;
  margin-bottom: 40px;
  font-weight: 900;
  letter-spacing: 2px;
  animation: slideInDown 0.8s ease;
}/* Container Styling */


.linkpageContainer {
  font-family: 'Poppins', sans-serif;
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  min-height: 100vh;
  animation: fadeIn 1.2s ease-in-out; /* Fade-in animation */
}

/* Profile Section */
.profile {
  text-align: center;
  margin-bottom: 30px;
}

.profileImg {
  border-radius: 50%;
  width: 180px;
  height: 180px;
  object-fit: cover;
  border: 4px solid #fff;
  box-shadow: 0 8px 30px rgba(9, 102, 223, 0.637);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  animation: bounceIn 1s ease;
}

.profileImg:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 35px rgb(0, 81, 255);
}

.tagline {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
  animation: fadeIn 1.5s ease;
}

/* Links Section */
.links {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
}

.links a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: #2c1e1e;
  color: #fff;
  border-radius: 40px;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 20px rgb(129, 22, 22);
  animation: linkAppear 0.6s ease both;
}

.links a:hover {
  background-color: #000000;
  transform: translateY(-5px);
  box-shadow: 0 6px 25px rgb(255, 0, 0);
}

.icon {
  margin-right: 15px;
  font-size: 1.8rem;
}

/* Icon Colors */
.youtube {
  color: #FF0000;
}

.instagram {
  color: #E4405F;
}

.discord {
  color: #7289DA;
}

.whatsapp {
  color: #25D366;
}

.twitter {
  color: #1DA1F2;
}

/* Keyframe Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
            animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes linkAppear {
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
